<template>
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="row">
                <div class="col-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body text-center">
                            <img
                                src="@/assets/images/404.png"
                                class="img-fluid mx-auto d-table my-5"
                                alt=""
                            />
                            <h1>404</h1>
                            <h5>
                                 Página não encontrada verifique o endereço e
                                tente novamente.
                            </h5>
                            <router-link
                                :to="{ name: 'Dashboard' }"
                                class="btn btn-primary mt-3 mb-5"
                            >
                                Dashboard</router-link
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style>
</style>